import { useConfig } from 'providers/ConfigProvider';

export default function Tag(props) {
    return (
        <button className={'rounded-full py-1.5 xl:py-2 px-3 xl:px-4 transition group ' + (props.active ? 'bg-mma-blue hover:bg-mma-yellow ' : 'bg-neutral-200 hover:bg-mma-yellow ') + (props.className || '')} onClick={props.onClick}>

            <div className={'text-xs xl:text-sm font-bold transition group-hover:text-neutral-700 whitespace-nowrap ' + (props.active ? 'text-white' : 'text-mma-blue')}>{props.label}</div>

        </button>
    );
}