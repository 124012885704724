import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CreatorLister } from 'pages/CreatorListPage';
import Timeline, { GenericCell } from 'components/Timeline';

export default function SearchPage(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState([]);
    const [searchTagId, setSearchTagId] = useState(null);
    const [searchLabel, setSearchLabel] = useState('');

    const [creatorSearchResult, setCreatorSearchResult] = useState([]);
    const [creationSearchResult, setCreationSearchResult] = useState([]);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('kifejezes')) setSearchTerm(searchParams.get('kifejezes'));
        if (searchParams.get('tipus')) setSearchType(searchParams.get('tipus').split(','));
        if (searchParams.get('targyszo')) setSearchTagId(searchParams.get('targyszo'));
        if (searchParams.get('cim')) setSearchLabel(searchParams.get('cim'));
    }, [searchParams]);

    useEffect(() => {
        if (searchTerm && searchTerm !== '') {
            const url = global.backingsEndpoint + 'contentControl/basicFinder'
            const body = { 'queryString': searchTerm }

            axios.post(url, body).then(function (response) {
                if (response.data) {
                    if (response.data[1]) setCreatorSearchResult(response.data[1]);
                    if (response.data[0]) setCreationSearchResult(response.data[0]);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
        if (searchTagId) {
            const url = global.backingsEndpoint + 'contentControl/getList'
            const body = { 'firstResult': 0, 'maxResults': 100000, 'personIdList': null, 'queryString': null, 'contentType': null, 'imageNeeded': false, 'conceptionIdList': [searchTagId] }

            axios.post(url, body).then(function (response) {
                if (response.data) setCreationSearchResult(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [searchTerm, searchTagId]);

    const titleClassName = 'w-full text-mma-blue text-md sm:text-xl uppercase font-bold';
    const messageClassName = 'w-full text-neutral-400 font-slab';

    return (
        <div className={'w-full flex flex-col gap-8 lg:gap-16 items-center pb-page pt-page  ' + (props.className || '')}>

            {(searchType.length === 0 || searchType.indexOf('alkoto') > -1) && <div className='w-full px-site flex flex-col gap-4'>
                <SearchBlockTitle label='Alkotók' subLabel={searchLabel} />
                {/* <div className={titleClassName}>Alkotók{searchLabel ? (' - ' + searchLabel) : ''}</div> */}
                {creatorSearchResult.length > 0 ? <CreatorLister data={creatorSearchResult} /> : <div className={messageClassName}>Sajnos nem található alkotó a megadott {searchTerm !== '' ? <strong>"{searchTerm}"</strong> : ''} keresésésre.</div>}
            </div>}

            {(searchType.length === 0 || searchType.indexOf('alkotas') > -1) && <div className='w-full flex flex-col gap-4'>
                <SearchBlockTitle className='px-site' label='Alkotások' subLabel={searchLabel} />
                {/* <div className={'px-site ' + titleClassName}>Alkotások{searchLabel ? (' - ' + searchLabel) : ''}</div> */}
                {creationSearchResult.length > 0 ? <Timeline data={creationSearchResult} child={<GenericCell />} /> : <div className={'px-site ' + messageClassName}>Sajnos nem található alkotás a megadott  {searchTerm !== '' ? <strong>"{searchTerm}"</strong> : ''} keresésésre.</div>}
            </div>}

        </div>
    );
}

function SearchBlockTitle(props) {
    return (
        <div className={'w-full text-mma-blue text-md sm:text-xl uppercase font-bold ' + props.className}>
            {props.label} - {props.subLabel ? <span className='text-mma-yellow'>{props.subLabel}</span> : ''}
        </div>
    );
}