import { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';

import { useConfig } from 'providers/ConfigProvider';

export default function CategoryFilter(props) {
    const { categoryList } = useConfig();

    return (
        <div className={(props.scroll ? 'w-full h-full flex gap-4 overflow-x-scroll ' : 'w-full flex gap-1 lg:gap-2 2xl:gap-3 ') + (props.className || '')}>

            {categoryList.map((item) => { return <CategoryButton key={item.key} data={item} className={props.scroll ? 'h-full' : 'flex-1'} /> })}

        </div>
    );
}

function CategoryButton(props) {
    const [hover, setHover] = useState(false);
    const { toggleFilter, isFilterActive, setSingleFilter } = useConfig();

    return (
        <button className={'relative aspect-square flex items-center justify-center transition ' + (isFilterActive(props.data.key) ? 'bg-mma-blue ' : 'bg-neutral-200 ') + (props.className || '')} onClick={() => /*toggleFilter(props.data.key)*/setSingleFilter(props.data.key)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

            <img className='h-[70%] aspect-square transition' src={(isFilterActive(props.data.key) ? 'assets/images/icons/dark/' : 'assets/images/icons/light/') + props.data.icon} />

            <div className='hidden md:block absolute w-full bottom-0'>
                <motion.div
                    className='relative w-full text-center'
                    initial={hover ? 'visible' : 'hidden'}
                    animate={hover ? 'visible' : 'hidden'}
                    variants={{ 'visible': { opacity: 1, rotateX: 0 }, 'hidden': { opacity: 0, rotateX: 70 } }}>
                    <div className='absolute w-full top-0 text-[11px] text-mma-blue uppercase font-bold flex justify-center pt-2'>
                        {props.data.label}
                    </div>
                </motion.div>
            </div>

        </button>
    );
}

export function StickyCategoryFilter(props) {
    const [y, setY] = useState(window.scrollY);
    const [visible, setVisible] = useState(true);

    const handleNavigation = useCallback(e => {
        const window = e.currentTarget;
        if (y > window.scrollY) setVisible(true);
        else if (y < window.scrollY) setVisible(false);
        setY(window.scrollY);
    }, [y]);

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener('scroll', handleNavigation);
        return () => { window.removeEventListener('scroll', handleNavigation); };
    }, [handleNavigation]);

    return (
        <motion.div
            className={'fixed bottom-0 left-0 w-full bg-white-glass h-20 shadow-3xl ' + (props.className || '')}
            style={{ originX: 0.5, originY: 1, transformPerspective: 1500, backfaceVisibility: 'hidden' }}
            initial={visible ? 'visible' : 'hidden'}
            animate={visible ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, rotateX: 0 }, 'hidden': { opacity: 0, rotateX: 70 } }}>

            <CategoryFilter scroll className='px-site py-site' />

        </motion.div>
    );
}