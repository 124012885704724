import { useState, useEffect } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';

import TextFilter from 'components/TextFilter';
import CategoryFilter from 'components/CategoryFilter';


export default function Filter(props) {
    return (
        <div className={' ' + (props.className || '')}>

            <div className='hidden md:block col-span-full md:col-span-2'>
                <CategoryFilter />
            </div>

            <div className='col-span-full md:col-span-2 xl:col-span-3'>
                <TextFilter className='h-full' placeholder={props.placeholder} value={props.terms} onChange={(e) => props.onTermsChanged(e.target.value)} />
            </div>

        </div>
    );
}