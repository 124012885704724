// import colors from 'tailwindcss/colors';
// // import resolveConfig from 'tailwindcss/resolveConfig'
// // import tailwindConfig from '../../../tailwind.config.js'

// // const fullConfig = resolveConfig(tailwindConfig)

// console.log(fullConfig);

const defaultColor = 'blue';
const colorList = ['gray', 'cyan', 'yellow', 'blue'];
const huColorMap = { 'szurke': 'gray', 'turkiz': 'cyan', 'sarga': 'yellow', 'kek': 'blue' }
const colorConfig = {
    'blue': {
        backgroundClassName: 'bg-mma-blue',
        highlightBackgroundClassName: 'bg-mma-yellow',
        textClassName: 'text-mma-blue',
        highlightTextClassName: 'text-white',
        contrastingTextClassName: 'text-white',
    },
    'gray': {
        backgroundClassName: 'bg-neutral-200',
        highlightBackgroundClassName: 'bg-mma-blue',
        textClassName: 'text-neutral-200',
        highlightTextClassName: 'text-mma-blue',
        contrastingTextClassName: 'text-black',
    },
    'yellow': {
        backgroundClassName: 'bg-mma-yellow',
        highlightBackgroundClassName: 'bg-mma-blue',
        textClassName: 'text-mma-yellow',
        highlightTextClassName: 'text-mma-blue',
        contrastingTextClassName: 'text-black',
    },
    'cyan': {
        backgroundClassName: 'bg-mma-cyan',
        highlightBackgroundClassName: 'bg-mma-yellow',
        textClassName: 'text-mma-cyan',
        highlightTextClassName: 'text-mma-blue',
        contrastingTextClassName: 'text-black',
    },
}

export const getColorConfig = (key, hu) => {
    let _key = defaultColor;
    let __key = hu ? huColorMap[key] : key;
    if (__key && colorConfig.hasOwnProperty(__key)) _key = __key;
    return _key ? colorConfig[_key] : null;
};

// export const getColorConfig = (key, ignoreDefault) => {
//     let _key = ignoreDefault ? null : defaultColor;
//     if (key && colorConfig.hasOwnProperty(key)) _key = key;
//     return _key ? colorConfig[_key] : null;
// };

export const getColorByIndex = (index) => {
    return colorList[index % colorList.length];
};

export const getColorByHu = (color) => {
    return huColorMap[color];
};

// export const getColorByArrayIndex = (array, index) => {
//     return colorList[index % array.length];
// };

export const getByLengthBreakpoint = (text, breakpoints) => {
    const length = text.length;
    let result = breakpoints[0];
    for (let key in breakpoints) {
        if (length > key)
            result = breakpoints[key];
    }
    return result;
};