import { useState, createContext, useContext } from 'react';
import { fakerHU as faker } from '@faker-js/faker';

import { getByKeyValue } from 'utils/GenericUtils';

export const ConfigContext = createContext(null);
export const useConfig = () => { return useContext(ConfigContext); };

export default function ConfigProvider(props) {
	const categoryList = [
		{ 'key': 'architecture', 'label': 'Építőművészet', 'icon': 'architectural.svg' },
		{ 'key': 'film', 'label': 'Filmművészet', 'icon': 'film.svg' },
		{ 'key': 'photo', 'label': 'Fotóművészet', 'icon': 'photo.svg' },
		{ 'key': 'industrial', 'label': 'Iparművészet és Tervezőművészet', 'icon': 'industrial.svg' },
		{ 'key': 'literature', 'label': 'Irodalom', 'icon': 'literature.svg' },
		{ 'key': 'fine', 'label': 'Képzőművészet', 'icon': 'fine.svg' },
		{ 'key': 'theory', 'label': 'Művészetelmélet', 'icon': 'theory.svg' },
		{ 'key': 'folk', 'label': 'Népművészet', 'icon': 'folk.svg' },
		{ 'key': 'theater', 'label': 'Színházművészet', 'icon': 'theater.svg' },
		{ 'key': 'music', 'label': 'Zeneművészet', 'icon': 'music.svg' },
	];

	const [filter, setFilter] = useState([]);

	const toggleFilter = (key) => {
		let _filter = [...filter];
		if (filter.indexOf(key) > -1) _filter.splice(filter.indexOf(key), 1);
		else _filter.push(key);
		setFilter(_filter);
	};

	const setSingleFilter = (key) => { setFilter([key]); };

	const setAllFilter = () => {
		let _filter = []; for (let i = 0; i < categoryList.length; i++) _filter.push(categoryList[i].key); setFilter(_filter);
	};

	const isFilterActive = (key) => { return filter.indexOf(key) > -1; };

	const getCategoryByKey = (key) => { return getByKeyValue(categoryList, 'key', key); };

	const getAllCategoryKeyArray = () => {
		let arr = [];
		for (let i = 0; i < categoryList.length; i++) arr.push(categoryList[i].key);
		return arr;
	};

	const value = {
		categoryList,
		filter,
		setFilter,
		toggleFilter,
		setSingleFilter,
		setAllFilter,
		isFilterActive,
		getCategoryByKey,
		getAllCategoryKeyArray,
	};

	return (
		<ConfigContext.Provider value={value}>
			{props.children}
		</ConfigContext.Provider>
	);
}
