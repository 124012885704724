import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import GoogleMapReact from 'google-map-react';
import { FaMapMarker } from "react-icons/fa";
import { HiMapPin } from "react-icons/hi2";

import Supercluster from 'supercluster';

import mapConfig from 'config/mapConfig.js'

export default function Map(props) {
    const [mapType, setMapType] = useState('HYBRID');

    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(15);

    const [supercluster, setSupercluster] = useState(null);
    const [markers, setMarkers] = useState(null);

    const handleApiLoaded = (map, maps) => {
        setMap(map);
        setMaps(maps);
    }

    useEffect(() => {
        if (props.data) {
            // setMarkers(props.data);

            let list = [];
            for (let i = 0; i < props.data.length; i++) {
                const item = props.data[i];

                if (item.geoData) {
                    const mapItem = item;

                    list.push({
                        type: 'Feature',
                        properties: { cluster: false },
                        geometry: { type: 'Point', coordinates: [parseFloat(mapItem.geoData.lng), parseFloat(mapItem.geoData.lat)] },
                        data: item,
                        pointerData: mapItem
                    });
                }
            }

            const supercluster = new Supercluster({ radius: 75, maxZoom: 100 });
            supercluster.load(list);

            setSupercluster(supercluster);
        }
        //   if (map&&bounds)
        //     map.fitBounds(bounds)
    }, [props.data]);

    useEffect(() => {
        if (supercluster && bounds && zoom)
            setMarkers(supercluster.getClusters(bounds, zoom));

    }, [bounds, zoom, supercluster]);

    // useEffect(() => {
    //     if (supercluster && bounds && zoom)
    //         setMarkers(supercluster.getClusters(bounds, zoom));

    // }, [bounds, zoom, supercluster]);

    return (
        <div className={'w-full bg-neutral-200 ' + (props.className || '')}>

            <GoogleMapReact
                key='map'
                bootstrapURLKeys={{ key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg', language: 'hu-HU', region: 'hu', }}
                options={map => ({
                    disableDefaultUI: true,
                    styles: mapConfig.styles,
                    mapTypeId: map.MapTypeId[mapType],
                    mapTypeControl: true,
                    zoomControl: true,
                    streetViewControl: true,
                })}
                defaultCenter={{ lat: mapConfig.defaultCoordinates.lat, lng: mapConfig.defaultCoordinates.lng, }}
                onChange={({ zoom, bounds }) => { setZoom(zoom); setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]); }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                defaultZoom={6}
                disableDefaultUI={true}>

                {markers && markers.map((cluster, index) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                    if (isCluster) { return (<Cluster key={index} lat={latitude} lng={longitude} pointCount={pointCount} onClick={() => { const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20); map.setZoom(expansionZoom); map.panTo({ lat: latitude, lng: longitude }); }} />); }

                    return (<Marker key={index} lat={latitude} lng={longitude} data={cluster.data.itemData} pointerData={cluster.pointerData} />);

                })}

                {/* {props.data && props.data.map((item, index) => { return (<Marker key={index} lat={item.geoData.lat} lng={parseFloat(item.geoData.lng)} data={item.itemData} />); })} */}

            </GoogleMapReact>

        </div>
    );
}

export function Cluster(props) {
    return (
        <div className='w-8 h-8 bg-mma-yellow rounded-full ring-4 ring-offset-0 ring-opacity-50 ring-mma-yellow flex items-center justify-center shadow-lg cursor-pointer z-10' onClick={props.onClick}>
            <div className='text-mma-blue font-bold'>{props.pointCount}</div>
        </div>
    );
}

function Marker(props) {
    const [open, setOpen] = useState(false);

console.log(props.data);

    return (
        <Link to={'/alkotas/' + props.data.id} className={'relative w-0 h-0 flex flex-col items-center cursor-pointer ' + (open ? 'z-50' : 'z-10')}>

            <div className='absolute bottom-0 flex flex-col items-center' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>

                <motion.div
                    className={'w-36 p-2 bg-white/90 rounded-lg shadow-xl -mb-1.5 flex flex-col gap-1 pointer-events-none ' + (open ? 'block' : 'hidden')}
                    style={{ originX: 0.5, originY: 1, transformPerspective: 1500, backfaceVisibility: 'hidden' }}
                    initial={open ? 'visible' : 'hidden'}
                    animate={open ? 'visible' : 'hidden'}
                    variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 0.5 } }}>

                    {props.data.imageKey && <div className='w-full h-12 rounded-sm bg-no-repeat bg-cover bg-center' style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.imageKey + ')' }}></div>}
                    {props.data.title && <div className='font-bold text-[10px]'>{props.data.title}</div>}
                    {props.data.subTitle && <div className='text-[9px]'>{props.data.subTitle}</div>}
                    {props.data.content && <div>{props.data.content}</div>}

                </motion.div>

                <HiMapPin className='text-mma-yellow z-10 text-4xl' />

            </div>

        </Link>

    );
}