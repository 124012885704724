import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { StickyCategoryFilter } from 'components/CategoryFilter';
import Filter from 'components/Filter';

import { useConfig } from 'providers/ConfigProvider';

const gridClassName = 'grid grid-cols-3 sm:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-8';

export default function CreatorListPage(props) {
    const [list, setList] = useState([]);
    const [terms, setTerms] = useState('');

    const { filter } = useConfig();

    useEffect(() => {
        const url = global.backingsEndpoint + 'personControl/getList'
        const body = { 'firstResult': 0, 'maxResults': 1000, 'queryString': terms, "contentType": filter }

        axios.post(url, body).then(function (response) {
            if (response.data) setList(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, [filter, terms]);

    return (
        <div className={'flex-1 w-full px-site pb-page flex flex-col gap-4 sm:gap-10 lg:gap-12 pt-page ' + (props.className || '')}>

            <Filter className={gridClassName} terms={terms} onTermsChanged={(value) => setTerms(value)} placeholder='Alkotó keresése ...' />

            <CreatorLister data={list} />

            <StickyCategoryFilter className='flex md:hidden' />

        </div>
    );
}

export function CreatorLister(props) {
    return (
        <div className={gridClassName}>{props.data.map((item) => { return <Creator key={item.id} data={item} /> })}</div>
    );
}

function Creator(props) {
    return (
        <Link to={'/alkoto/' + props.data.id} className='w-full flex flex-col gap-2 items-center group'>
            <div className='relative w-full aspect-square rounded-full bg-mma-cyan overflow-hidden'>
                <div className='w-full h-full bg-top bg-cover grayscale group-hover:scale-105 transition duration-1000 ' style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.profilkep + ')' }} />
                <div className='absolute top-0 w-full h-full rounded-full bg-mma-cyan group-hover:opacity-100 opacity-0 mix-blend-multiply transition' />
            </div>
            <div>
                <div className='font-bold text-sm lg:text-base xl:text-lg text-center'>{props.data.elhalalozasIdeje ? '✝ ' : ''} {props.data.vezeteknev} {props.data.keresztnev1}{props.data.keresztnev2 ? (' ' + props.data.keresztnev2) : ''}</div>
                {props.data.szakma && <div className='text-[10px] sm:text-sm text-center'>{props.data.szakma}</div>}
            </div>
        </Link>
    );
}