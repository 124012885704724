import { useState, useEffect } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';

export default function TextFilter(props) {
    const [index, setIndex] = useState(0);

    return (
        <div className={'relative ' + (props.className || '')}>

            <input className='w-full min-h-full bg-neutral-200 pl-4 pr-8 py-2 text-[13px] font-slab outline-none' placeholder={props.placeholder} value={props.value} onChange={props.onChange} />

            <button className='absolute right-0 top-0 h-full aspect-square flex items-center justify-center' onClick={() => props.onButtonClick && props.onButtonClick()}>
                <PiMagnifyingGlass className='text-[22px]' />
            </button>

        </div>
    );
}