import axios from 'axios';
import { useState, useEffect } from 'react';

import { StickyCategoryFilter } from 'components/CategoryFilter';
import Filter from 'components/Filter';
import Timeline, { GenericCell } from 'components/Timeline';

import { useConfig } from 'providers/ConfigProvider';

export default function CreationListPage(props) {
    const [list, setList] = useState([]);
    const [terms, setTerms] = useState('');

    const {setSingleFilter, filter } = useConfig();

    useEffect(() => {
        setSingleFilter('architecture');
    }, []);

    useEffect(() => {
        const url = global.backingsEndpoint + 'contentControl/getList'
        const body = { 'firstResult': 0, 'maxResults': 100000, 'personIdList': [], 'queryString': terms, 'contentType': filter, 'imageNeeded': false, 'conceptionIdList': [] }

        axios.post(url, body).then(function (response) {
            if (response.data) setList(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, [filter, terms]);

    const gridClassName = 'grid grid-cols-3 sm:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-8';

    return (
        <div className={'flex-1 w-full pb-page flex flex-col gap-4 sm:gap-10 lg:gap-12 pt-page ' + (props.className || '')}>

            <div className='px-site'>
                <Filter className={gridClassName} terms={terms} onTermsChanged={(value) => setTerms(value)} placeholder='Alkotás keresése ...' />
            </div>

            <Timeline data={list} child={<GenericCell />} />

            <StickyCategoryFilter className='flex md:hidden' />

        </div>
    );
}