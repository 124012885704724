import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import { PiCaretDownBold } from 'react-icons/pi';
import { IoMdQuote, IoMdClose } from 'react-icons/io';
import { FiDownload } from 'react-icons/fi';

import Timeline, { GenericCell } from 'components/Timeline';
import Map from 'components/Map';

import Tag from 'elements/Tag';

import { getColorConfig } from 'utils/StyleUtils';
import { __tempConvertLife } from 'utils/GenericUtils';

export default function CreatorPage(props) {
    const [item, setItem] = useState(null);
    const [creationList, setCreationList] = useState([]);
    const [lifeList, setLifeList] = useState([]);
    const [mode, setMode] = useState(null); //BIO, AWARDS, TRACK, MAP, MOTTO

    const timelineRef = useRef(null)

    let { id } = useParams();

    useEffect(() => {
        if (id) {
            getData(id);
            getCreationList(id);
            getLifeList(id);
        }
    }, [id]);

    const getData = (id) => {
        const url = global.backingsEndpoint + 'personControl/getData'
        const body = { 'personId': id }

        axios.post(url, body).then(function (response) {
            if (response.data) setItem(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getCreationList = (id) => {
        const url = global.backingsEndpoint + 'contentControl/getList'
        const body = { 'firstResult': 0, 'maxResults': 1000, 'personIdList': [parseInt(id)], 'contentType': ['architecture'], 'queryString': '', 'imageNeeded': false, 'conceptionIdList': [] }

        axios.post(url, body).then(function (response) {
            if (response.data) setCreationList(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getLifeList = (id) => {
        const url = global.backingsEndpoint + 'personControl/getWalkOfLife'
        const body = { 'personId': parseInt(id) }

        axios.post(url, body).then(function (response) {
            if (response.data) setLifeList(__tempConvertLife(response.data));
        }).catch(function (error) {
            console.log(error);
        });
    };

    const gridGapClassName = 'gap-4 lg:gap-8'

    const createMapData = () => {
        let list = [];
        for (let i = 0; i < creationList.length; i++) {
            if (creationList[i].koordinatak) {
                if (creationList[i].koordinatak) {
                    let coords = creationList[i].koordinatak.replaceAll(' ', '');

                    if (coords && coords !== '') {
                        const geo = coords.split(',');
                        // console.log(creationList[i]);
                        list.push({
                            'geoData': {
                                'lat': geo[0].trim(),
                                'lng': geo[1].trim(),
                            },
                            'itemData': {
                                'id': creationList[i].id,
                                'imageKey': creationList[i].fokep || null,
                                'title': creationList[i].nev,
                                'subTitle': item.nevDto.megjelenitendoNev + ', ' + creationList[i].keletkezesIdopontjaInt,
                            }
                        });
                    }
                }
            }
        }
        return list;
    };

    const handleDownload = (type) => {
        window.open((global.backingsEndpoint + 'personControl/getPDFFile?personId=' + id + '&pdfType=' + type), '_blank');
    };

    return (
        <div className={'w-full flex flex-col gap-8 pb-page pt-page ' + (props.className || '')}>

            {item && <>

                <div className={'w-full px-site grid grid-cols-1 sm:grid-cols-5 gap-4 lg:gap-8 ' + gridGapClassName}>

                    <CreatorHeader className='col-span-full md:col-span-3 lg:col-span-2 2xl:col-span-2' data={item} />

                    <CreatorGrid className='hidden sm:flex col-span-full md:col-span-2 lg:col-span-3' data={item} mode={mode} onModeChange={(mode) => setMode(mode)} />
                    <CreatorMobileGrid className='flex sm:hidden col-span-full' data={item} mode={mode} onModeChange={(mode) => setMode(mode)} onScrollToTimeline={() => timelineRef.current.scrollIntoView()} />

                    {item && <>

                        {/* <Graph /> */}

                        {mode === 'BIO' && <CreatorTextContent className='col-span-full 2xl:col-start-2' title={item.nevDto.megjelenitendoNev + ' életrajza'} content={item.eletrajz} onClose={() => setMode(null)} onDownload={() => handleDownload('eletrajz')} />}
                        {mode === 'AWARDS' && <CreatorTextContent className='col-span-full 2xl:col-start-2' title={item.nevDto.megjelenitendoNev + ' díjai'} content={item.dijak} onClose={() => setMode(null)} onDownload={() => handleDownload('dijak')} />}
                        {mode === 'TRACK' && <CreatorTextContent className='col-span-full 2xl:col-start-2' title={item.nevDto.megjelenitendoNev + ' pályaképe'} content={item.palyakep} onClose={() => setMode(null)} onDownload={() => handleDownload('palyakep')} />}
                        {mode == 'MOTTO' && <CreatorMotto className='col-span-full 2xl:col-start-3' data={item.arsPoetica} onClose={() => setMode(null)} onDownload={() => handleDownload('arsPoetica')} />}
                        {mode == 'MAP' && <div className='relative col-span-full'>
                            <button className='absolute m-4 top-0 right-0 text-white/75 hover:text-white z-50 bg-black/50 rounded' onClick={() => setMode(null)}><IoMdClose className='text-3xl transition' /></button>
                            <Map className='w-full aspect-square sm:aspect-video' data={createMapData()} />
                        </div>}

                        {/* {mode === 'BIO' && <CreatorTextContent className='col-span-full 2xl:col-start-2' title={item.nevDto.megjelenitendoNev + ' életrajza'} content={item.eletrajz} onClose={() => setMode(null)} />} */}

                    </>}

                </div>

                <CreatorTimeline refProp={timelineRef} className='col-span-full' id={id} creationList={creationList} lifeList={lifeList} />

            </>}

        </div>
    );
}

function CreatorMobileGrid(props) {
    return (
        <div className={'grid grid-cols-3 gap-0 ' + (props.className || '')}>
            {/* <CreatorMobileGridItem color='blue' label='Lexikon' onClick={() => props.onModeChange('BIO')} /> */}
            {/* <CreatorMobileGridItem color='cyan' label='Pályakép' onClick={() => props.onModeChange('TRACK')} /> */}
            <CreatorMobileGridItem color='blue' label='Díjak' onClick={() => props.onModeChange('AWARDS')} />
            <CreatorMobileGridItem color='cyan' label='Munkák' onClick={() => props.onScrollToTimeline()} />
            {/* <CreatorMobileGridItem color='yellow' label='Mottó' onClick={() => props.onModeChange('MOTTO')} /> */}
            <CreatorMobileGridItem color='yellow' label='Párhuzamos életutak' to={'/parhuzamos-eletutak?bal=' + props.data.id} />
            {/* <CreatorMobileGridItem color='cyan' label='Térképek' onClick={() => props.onModeChange('MAP')} /> */}
        </div>
    );
}

function CreatorMobileGridItem(props) {
    return (
        <Link to={props.to} params={props.params} className={'relative flex-1 flex flex-col items-center gap-2 justify-center px-2 py-1.5 transition ' + (props.disabled ? ' blur-[2px] ' : '') + getColorConfig(props.color).backgroundClassName + ' ' + (props.className || '')} onClick={props.onClick}>

            <div className={'uppercase text-xs font-bold text-center ' + getColorConfig(props.color).highlightTextClassName}>{props.label}</div>

        </Link>
    );
}

function CreatorHeader(props) {
    const getMembership = (title, from, to) => {
        if (title && from) return 'Az MMA ' + title + ' tagja (' + from + '-' + (to ? to : '') + ')';
        return null;
    };

    const getBirthDeath = () => {
        function getCityDate(placeDto, date) {
            let cd = '';
            if (placeDto && placeDto.city) cd += placeDto.city;
            if (date) cd += (cd === '' ? '' : ', ') + format(new Date(date), 'yyyy. MMMM d.', { locale: hu })
            return cd;
        };

        let birth = getCityDate(props.data.szuletesiHelyDto, props.data.szuletesiIdo);
        let death = getCityDate(props.data.elhalalozasHelyeDto, props.data.elhalalozasIdeje);

        return birth + (death !== '' ? (' - ' + death) : '');
    };

    return (
        <div className={'grid grid-cols-2 gap-4 lg:gap-8 ' + (props.className || '')}>

            <div className='aspect-square bg-image-load rounded-full overflow-hidden'>
                <div className='w-full h-full bg-top bg-cover grayscale' style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.profilkep + ')' }} />
            </div>

            <div className='flex flex-col gap-2 sm:gap-4'>

                <div className='font-slab font-bold text-lg sm:text-2xl lg:text-3xl'>{(props.data.elhalalozasIdeje ? '✝ ' : '') + props.data.nevDto.megjelenitendoNev}</div>

                <div className='flex flex-col gap-1 sm:gap-2 text-xs sm:text-sm 2xl:text-base font-light'>
                    {props.data.szakmaOndef && <div>{props.data.szakmaOndef}</div>}
                    <div>{getBirthDeath()}</div>
                    {getMembership(props.data.elozoElottiTagsagJogcim, props.data.elozoElottiTagsagKezdete, props.data.elozoElottiTagsagVege) && <div dangerouslySetInnerHTML={{ __html: getMembership(props.data.elozoElottiTagsagJogcim, props.data.elozoElottiTagsagKezdete, props.data.elozoElottiTagsagVege) }} />}
                    {getMembership(props.data.elozoTagsagJogcim, props.data.elozoTagsagKezdete, props.data.elozoTagsagVege) && <div dangerouslySetInnerHTML={{ __html: getMembership(props.data.elozoTagsagJogcim, props.data.elozoTagsagKezdete, props.data.elozoTagsagVege) }} />}
                    {getMembership(props.data.tagsagJogcim, props.data.tagsagKezdete, props.data.tagsagVege) && <div dangerouslySetInnerHTML={{ __html: getMembership(props.data.tagsagJogcim, props.data.tagsagKezdete, props.data.tagsagVege) }} />}
                    {props.data.tagozatEnumValue && <div>{props.data.tagozatEnumValue}</div>}
                </div>

            </div>

        </div>
    );
}

function CreatorGrid(props) {
    return (
        <div className={'w-full items-start ' + (props.className || '')}>

            <div className='w-full grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-2'>

                <CreatorGridItem className='lg:aspect-square' color='yellow' label='Lexikon' onClick={() => props.onModeChange('BIO')} />
                <CreatorGridItem className='xl:col-span-2 lg:aspect-square xl:aspect-auto xl:h-full' color='blue' label='Pályakép' onClick={() => props.onModeChange('TRACK')} />
                <CreatorGridItem className='lg:aspect-square' color='cyan' label='Tőle/Róla' disabled />
                <CreatorGridItem className='lg:aspect-square' color='yellow' label='Párhuzamos életutak' to={'/parhuzamos-eletutak?bal=' + props.data.id} params={{ bal: props.data.id }} />
                <CreatorGridItem className='lg:aspect-square' color='gray' label='Térképek' background={'/assets/images/etc/maps.png'} onClick={() => props.onModeChange('MAP')} />
                <CreatorGridItem className='lg:aspect-square' color='cyan' label='Díjak' onClick={() => props.onModeChange('AWARDS')} />
                <CreatorGridItem className='xl:col-span-3 lg:aspect-square xl:aspect-auto xl:h-full' color='yellow' label='Mottó' quote={props.data.arsPoetica} onClick={() => props.onModeChange('MOTTO')} />
                <CreatorGridItem className='xl:col-span-2 lg:aspect-square xl:aspect-auto xl:h-full' color='gray' label='Kapcsolódások' background={'/assets/images/etc/connections.png'} disabled />

            </div>

        </div>
    );
}

function CreatorGridItem(props) {
    const labelClassName = 'uppercase text-xs xl:text-sm font-bold text-center z-10 ' + getColorConfig(props.color).highlightTextClassName;

    return (
        <Link to={props.to} params={props.params} className={'relative flex flex-col items-center gap-2 justify-center py-4 px-2 bg-cover transition ' + (props.disabled ? ' blur-[2px] ' : ' shadow-xl hover:scale-[.975] ') + getColorConfig(props.color).backgroundClassName + ' ' + (props.className || '')} onClick={props.onClick}>

            <div className='absolute w-full h-full bg-cover bg-center opacity-40 mix-blend-multiply' style={{ backgroundImage: 'url(' + props.background + ')' }} />

            {!props.quote && <div className={labelClassName}>{props.label}</div>}

            {props.quote && <div className='absolute w-full h-full top-0 left-0 flex flex-col gap-1 items-center justify-center px-4 bg-white/50'>
                <div className={labelClassName}>{props.label}</div>
                {props.quote && <div className='hidden xl:flex w-full'>
                    <IoMdQuote className='absolute top-0 left-0 text-[30px] text-mma-yellow m-2' />
                    <IoMdQuote className='absolute bottom-0 right-0 text-[30px] text-mma-yellow m-2 rotate-180' />
                    <div className='w-full text-center text-xs px-2 font-medium  line-clamp-1 2xl:line-clamp-3 font-slab'>{props.quote}</div>
                </div>}
            </div>}

        </Link>
    );
}

function CreatorTextContent(props) {
    const [openable, setOpenable] = useState(true);
    const [open, setOpen] = useState(false);

    const [height, setHeight] = useState(0);
    const ref = useRef(null)

    useEffect(() => {
        const handleResize = () => { setHeight(ref.current.offsetHeight); };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div className={'flex flex-col gap-4 ' + (props.className || '')}>

            <div className='flex items-center justify-end'>
                {props.title && <div className='text-xl font-bold flex-1'>{props.title}</div>}
                <div className=' flex flex-row items-center gap-2'>
                    <button className='text-mma-blue hover:text-mma-yellow' onClick={props.onDownload}><FiDownload className='text-2xl transition' /></button>
                    <button className='text-mma-blue hover:text-mma-yellow' onClick={props.onClose}><IoMdClose className='text-3xl transition' /></button>
                </div>
            </div>

            <motion.div
                ref={ref}
                className={'relative w-full overflow-hidden group ' + (open ? 'pb-12' : '')}
                initial={(!openable || open) ? 'visible' : 'hidden'}
                animate={(!openable || open) ? 'visible' : 'hidden'}
                variants={{ 'visible': { height: 'auto' }, 'hidden': { height: 320 } }}>

                <div className='lg:columns-2 text-sm leading-relaxed' dangerouslySetInnerHTML={{ __html: props.content }}></div>

                <button className={'absolute bottom-0 w-full h-24 flex items-end justify-center ' + (open ? '' : 'bg-gradient-to-b from-transparent via-white/80  to-white ')} onClick={() => setOpen(!open)}>

                    <motion.div
                        ref={ref}
                        className='flex items-center justify-center'
                        initial={open ? 'visible' : 'hidden'}
                        animate={open ? 'visible' : 'hidden'}
                        variants={{ 'visible': { rotate: 180 }, 'hidden': { rotate: 0 } }}>

                        <PiCaretDownBold className={'text-3xl text-mma-blue hover:text-mma-yellow transition-colors ' + (open ? '-mb-8' : 'mb-0')} />

                    </motion.div>

                </button>

            </motion.div>

        </div>
    );
}

function CreatorMotto(props) {
    return (
        <div className={'relative bg-mma-yellow p-16 ' + (props.className || '')}>

            <div className='absolute m-4 top-0 right-0 flex flex-row items-center gap-2'>
                <button className='text-white/75 hover:text-white' onClick={props.onDownload}><FiDownload className='text-2xl transition' /></button>
                <button className='text-white/75 hover:text-white' onClick={props.onClose}><IoMdClose className='text-3xl transition' /></button>
            </div>

            <IoMdQuote className='absolute top-0 left-0 text-[50px] text-white/75 m-2' />
            <IoMdQuote className='absolute bottom-0 right-0 text-[50px] text-white/75 m-2 rotate-180' />

            <div className='text-lg font-slab text-center'>{props.data}</div>

        </div>
    );
}

function CreatorTimeline(props) {
    const [activeModeList, setActiveModeList] = useState(['CREATION', 'LIFE']); //CREATION, LIFE
    const [list, setList] = useState([]);

    const modeList = [
        { 'key': 'CREATION', 'label': 'Alkotások', 'list': props.creationList },
        { 'key': 'LIFE', 'label': 'Életút-események', 'list': props.lifeList }
    ];

    useEffect(() => {
        // console.log(activeModeList);
    }, [activeModeList]);

    useEffect(() => {
        let arr = [];
        for (let i = 0; i < modeList.length; i++)
            if (modeList[i].list && activeModeList.indexOf(modeList[i].key) > -1)
                arr = arr.concat(modeList[i].list);
        setList(arr);
    }, [activeModeList, props.creationList, props.lifeList]);

    const handleMode = (mode) => {
        let _activeModeList = [...activeModeList];

        let allModeList = [];
        for (let i = 0; i < modeList.length; i++) allModeList.push(modeList[i].key);

        if (_activeModeList.length === modeList.length) {
            if (activeModeList.indexOf(mode) > -1) _activeModeList = [mode];
        } else {
            if (activeModeList.indexOf(mode) > -1) _activeModeList = allModeList;
            else _activeModeList.push(mode);
        }

        setActiveModeList(_activeModeList);
    };

    return (
        <div ref={props.refProp} className={'w-full flex flex-col gap-8 ' + (props.className || '')}>

            {(props.creationList.length > 0 && props.lifeList.length > 0) && <div className='w-full flex gap-2 justify-end px-site'>
                {modeList.map((item, index) => { return <Tag onClick={() => handleMode(item.key)} label={item.label} active={activeModeList.indexOf(item.key) > -1} /> })}
            </div>}

            <Timeline data={list} child={<GenericCell />} lineColor={((activeModeList.length === 1 && activeModeList[0] === 'LIFE') || (props.creationList.length === 0 && props.lifeList.length > 0)) ? 'cyan' : 'yellow'} />

        </div>
    );
}

function Graph(props) {
    const [dimension, setDimension] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setDimension(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    return (
        <div ref={ref} className='w-full aspect-square bg-neutral-200'>
            <palladio-graph
                height={dimension + 'px'}
                width={dimension + 'px'}
                project-url={'/assets/palladio2.json'}>
            </palladio-graph>
        </div>
    );
}
