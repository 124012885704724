import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdQuote } from 'react-icons/io';

import ImageSlider from 'components/ImageSlider';

import { useConfig } from 'providers/ConfigProvider';
import { getColorConfig } from 'utils/StyleUtils';
import { parseISOWithOptions } from 'date-fns/fp';

const gridClassName = 'grid grid-cols-12 sm:gap-2';

export default function HomePage(props) {
    const [news, setNews] = useState(null);

    useEffect(() => {
        const url = global.backingsEndpoint + 'newsControl/getAllNews'
        const body = {}

        axios.post(url, body).then(function (response) {
            if (response.data) setNews(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    return (
        <div className={'w-full sm:p-2 sm:pb-0 ' + gridClassName + ' ' + (props.className || '')}>

            <div className='col-span-full h-64 bg-neutral-200'>{(news && news[0]) && <HomePageItem data={news[0]} />}</div>

            <div className='col-span-full sm:col-span-6 2xl:col-span-7 bg-neutral-200'>{(news && news[1]) && <HomePageItem data={news[1]} align='right' desktopForcedAlign />}</div>

            <div className='col-span-full sm:col-span-6 lg:col-span-3 sm:row-span-2 bg-neutral-200'>{(news && news[2]) && <HomePageItem data={news[2]} type='quote' />}</div>
            <div className='col-span-full sm:col-span-6 lg:col-span-3 2xl:col-span-2 sm:row-span-2 bg-neutral-200'><CategoryList /></div>

            <div className='col-span-full sm:col-span-6 2xl:col-span-7 bg-neutral-200'>{(news && news[3]) && <HomePageItem data={news[3]} desktopForcedAlign />}</div>

            {(news && news.length >= 4) && <EtcGrid data={news.slice(4)} />}

        </div>
    );
}

function EtcGrid(props) {
    const getRowList = (data, columns) => {
        let rows = [], temp = [];

        for (let i = 0; i < data.length; i++) {
            temp.push(data[i]);
            if (temp.length === columns) {
                rows.push(temp);
                temp = [];
            }
        }
        if (temp.length > 0) rows.push(temp);
        return rows;
    };

    return (
        <div className={'w-full flex flex-col sm:gap-2 col-span-full'}>
            {getRowList(props.data, 2).map((item, index) => { return <EtcGridRow key={index} data={item} index={index} /> })}
        </div>
    );
}

function EtcGridRow(props) {
    const getColSpan = (index) => {
        if (props.data.length === 1) return 'col-span-full';
        return index % 2 === (props.index ? 1 : 0) ? 'col-span-full sm:col-span-6 lg:col-span-5' : 'col-span-full sm:col-span-6 lg:col-span-7';
    };

    return (
        <div className={'w-full ' + gridClassName}>

            {props.data.map((item, index) => { return <HomePageItem className={getColSpan(index)} key={item.id} data={item} index={index} align={index % 2 ? 'left' : 'right'} /> })}

        </div>
    );
}

function HomePageItem(props) {
    const color = getColorConfig(props.data.color, true);

    const createImageList = (data) => {
        let list = [];
        if (props.data.kep) list.push({ key: props.data.kep });
        if (props.data.galeria)
            for (let i = 0; i < props.data.galeria.length; i++)
                list.push({ key: props.data.galeria[i].kep });
        return list.length > 0 ? list : null;
    };

    const getOrderForAlign = (align, isImage) => {
        let className = '';
        if (isImage) className = align === 'right' ? 'order-last' : 'order-first';
        else className = align === 'right' ? 'order-first' : 'order-last';
        if (!props.desktopForcedAlign) className += ' sm:order-none';
        return className;
    };

    return (
        <div href={props.data.link} className={'relative w-full h-full flex group ' + color.backgroundClassName + ' ' + (props.className || '')}>

            {props.type === 'quote' && <>
                <IoMdQuote className='absolute top-0 left-0 text-[30px] sm:text-[100px] text-mma-yellow m-5' />
                <IoMdQuote className='absolute bottom-0 right-0 text-[30px] sm:text-[100px] text-mma-yellow m-5 rotate-180' />
            </>}

            <a href={props.data.link} className={'flex-1 h-full flex flex-col gap-2 justify-center px-site sm:-mx-2 py-16 ' + getOrderForAlign(props.align, false)}>

                {props.data.nev && <div className={'font-slab font-bold texl-lg sm:text-2xl ' + color.contrastingTextClassName + ' ' + (props.type === 'quote' ? 'text-center' : '')}>{props.data.nev}</div>}
                {/* {props.data.leiras && <div className={'text-sm ' + color.highlightTextClassName}>{props.data.leiras}</div>} */}
                {props.data.leiras && <div className={'text-sm line-clamp-3 text-left ' + color.highlightTextClassName} dangerouslySetInnerHTML={{ __html: props.data.leiras }} />}

            </a>

            {((props.data.kep || props.data.galeria) && props.type !== 'quote') && <a href={props.data.galeria ? null : props.data.link} className={'w-[40%] bg-black/5 ' + getOrderForAlign(props.align, true)}>

                <ImageSlider dots arrows auto thumbnail data={createImageList()} slideClassName={'grayscale transition mix-blend-multiply ' + (props.data.link ? 'group-hover:scale-[1.015] duration-1000 group-hover:grayscale-0' : '')} color={color} />

            </a>}

        </div>
    );
}

function CategoryList(props) {
    const { categoryList } = useConfig();

    return (
        <div className='grid grid-cols-2 sm:grid-cols-1 gap-0.5 py-0.5'>

            {categoryList.map((item) => { return <CategoryButton key={item.key} data={item} /> })}

        </div>
    );
}

function CategoryButton(props) {
    const navigate = useNavigate();
    const { setSingleFilter } = useConfig();

    const handleOnClick = () => {
        setSingleFilter(props.data.key);
        navigate('/alkotok');
    };

    return (
        <button className='bg-mma-blue hover:bg-mma-yellow transition flex flex-row items-center gap-3 p-3' onClick={() => handleOnClick()}>

            <img alt={props.data.label} className='w-8 aspect-square' src={'assets/images/icons/dark/' + props.data.icon} />

            <div className='font-slab text-[10px] sm:text-sm lg:text-base text-white text-left uppercase sm:normal-case'>{props.data.label}</div>

        </button>
    );
}

// function Impressum(props) {
//     return (
//         <div className='bg-mma-blue flex flex-col gap-12 items-center py-12 '>

//             <div className='text-mma-yellow text-2xl font-bold uppercase'>Impresszum</div>

//             <div className='text-white leading-relaxed flex items-center flex-col xl:flex-row gap-4 xl:gap-0 xl:divide-x divide-white/10'>
//                 <ImpressumItem logo='/assets/images/logo/mma-white-transparent.png' link='https://mma.hu/' label='Kiadó' content='Magyar Művészeti Akadémia Titkársága' />
//                 <ImpressumItem label='Felelős kiadó' content='Dr. Richly Gábor főtitkár' />
//                 <ImpressumItem label='Felelős szerkesztő' content='Csete Örs főosztályvezető' />
//             </div>

//             <div className='flex flex-col gap-8 text-white items-center text-sm'>
//                 <ImpressumContactItem label='Kapcsolat' title='Magyar Művészeti Akadémia Titkársága, Tagozati és Dokumentációs Főosztály, Digitalizációs Központ' address='1051 Budapest, Vörösmarty tér 1., 4. emelet' email='digikozpont@mma.hu ' />
//                 <ImpressumContactItem href='https://creo.hu/' logo='/assets/images/logo/creo.png' label='Fejlesztés, tárhely szolgáltatás' title='creo.hu Kft.' address='1088 Budapest, Rákóczi út 19. 3/1a.' email='info@creo.hu' />
//             </div>

//             {/* <div className='text-white leading-relaxed text-sm text-center sm:-mx-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vestibulum vel erat et dictum. Suspendisse feugiat vitae tellus eu auctor. Maecenas volutpat vulputate enim, a auctor magna egestas vitae. In egestas mattis leo. Cras iaculis nisl ac facilisis rhoncus. Aliquam aliquet tellus vitae erat dictum imperdiet. Sed ut volutpat est. Etiam et elit vitae lectus molestie ornare sit amet quis nulla. In id massa non ligula dictum euismod id blandit turpis. Curabitur molestie sodales justo, in suscipit mauris vehicula nec. Pellentesque blandit purus vel tristique aliquet. Donec at lacus sem.</div> */}

//         </div>
//     );
// }

// function ImpressumItem(props) {
//     return (
//         <div className='flex flex-col sm:flex-row sm:gap-2 items-center px-4'>

//             <div className='font-bold font-slab'>{props.label}:</div>

//             <a href={props.link} target='_blank' rel='noreferrer' className='flex items-center gap-2'>
//                 {props.logo && <div className='w-[35px] opacity-50 group-hover:opacity-75 transition mb-0.5'><img alt='logo' src={props.logo} /></div>}
//                 <div className='font-thin'>{props.content}</div>
//             </a>

//         </div>
//     );
// }

// function ImpressumContactItem(props) {
//     return (
//         <div className='flex flex-row gap-2 items-center px-4'>

//             <div className='flex flex-col gap-2 items-center'>
//                 <ImpressumContactItemLine title={props.label} content={props.title} logo={props.logo} href={props.href} />
//                 <ImpressumContactItemLine title='Cím' content={props.address} />
//                 <ImpressumContactItemLine title='E-mail' content={props.email} href={'mailto:' + props.email} />
//             </div>

//         </div>
//     );
// }

// function ImpressumContactItemLine(props) {
//     return (
//         <div className='flex flex-col lg:flex-row items-center lg:gap-2 font-slab px-2'>

//             <div className='font-bold font-slab'>{props.title}:</div>

//             <a className={'flex items-center gap-2 opacity-90 transition ' + (props.href ? 'hover:opacity-100' : '')} href={props.href}>
//                 {props.logo && <div className='w-[60px] opacity-50 mb-0.5 px-0.5 mt-0.5'><img alt='logo' src={props.logo} /></div>}
//                 <div className='font-light text-center'>{props.content}</div>
//             </a>

//         </div>
//     );
// }