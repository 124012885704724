import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PiMapPinFill, PiShareNetworkBold } from 'react-icons/pi';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import Timeline, { GenericCell } from 'components/Timeline';
import Map from 'components/Map';
import ImageSlider from 'components/ImageSlider';
import Tag from 'elements/Tag';

import { getSearchUrl } from 'utils/GenericUtils';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() { setSize([window.innerWidth, window.innerHeight]); }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function CreationPage(props) {
    const [item, setItem] = useState(null);
    const [activeTagList, setActiveTagList] = useState([]);

    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const url = global.backingsEndpoint + 'contentControl/getData'
            const body = { 'contentId': id }

            axios.post(url, body).then(function (response) {
                if (response.data) setItem(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [id]);

    const handleTagListClick = (id, label) => {
        // let _activeTagList = [...activeTagList];
        // if (activeTagList.indexOf(id) > -1) _activeTagList.splice(activeTagList.indexOf(id), 1);
        // else _activeTagList.push(id);
        // setActiveTagList(_activeTagList);
        navigate(getSearchUrl(null, 'alkotas', id, label))
    };

    return (
        <div className={'w-full flex flex-col gap-8 pb-page pt-page ' + (props.className || '')}>

            {item && <>

                <div className='px-site flex justify-between items-center'>

                    {/* <Link to='/alkotasok' className='group'>
                        <HiOutlineChevronLeft className='text-4xl text-mma-blue hover:text-mma-yellow transition' />
                    </Link> */}

                    {/* <div className='w-full flex gap-2 justify-end bg-red-4000 overflow-hidden'>
                        {item.targyszoDtoList.map((item, index) => { return <Tag onClick={() => { handleTagListClick(item.id, item.nev) }} label={item.nev} active={activeTagList.indexOf(item.id) > -1} /> })}
                    </div> */}

                    <TagList data={item.targyszoDtoList} onTagClick={(id, label) => handleTagListClick(id, label)} />

                </div>

                <CreationHeader data={item} activeTagList={activeTagList} onTagChange={(activeTagList) => setActiveTagList(activeTagList)} />

                <RelatedCreationList personId={item.alkoto[0]} activeTagList={activeTagList} />

            </>}

        </div>
    );
}

function CreationHeader(props) {
    const [mode, setMode] = useState(!props.data.fokep ? 'MAP' : 'IMAGE'); //IMAGE, MAP
    const [activeIndex, setActiveIndex] = useState(0);

    const getImageArray = (main, etcDtoArr) => {
        let arr = [];
        if (main) arr.push({ key: main });
        if (etcDtoArr)
            for (let i = 0; i < etcDtoArr.length; i++)
                arr.push({ key: etcDtoArr[i].kep });
        return arr;
    };

    const createMapData = () => {
        const geo = props.data.keletkezesHelyeDto.koordinatak.split(',');
        let data = [{
            'geoData': {
                'lat': geo[0].trim(),
                'lng': geo[1].trim(),
            },
            'itemData': {
                'id': props.data.id,
                'imageKey': props.data.fokep || null,
                'title': props.data.nev,
                'subTitle': props.data.alkotoDtoList[0].nevDto.megjelenitendoNev + ', ' + props.data.keletkezesIdopontjaInt,
            }
        }];
        return data;
    };

    return (
        <div className='w-full flex flex-col md:gap-8'>

            <div className={'w-full grid grid-cols-1 sm:grid-cols-6 items-start gap-4 lg:gap-8 pb-page pt-page px-site-list ' + (props.className || '')}>

                <div className='col-span-2 h-full flex flex-col gap-4 order-2 sm:order-1 px-4 sm:px-0'>

                    <div className='flex flex-col gap-1'>
                        <div className='font-bold text-2xl font-slab'>{props.data.keletkezesIdopontjaInt}</div>
                        <Link className='text-3xl hover:text-mma-yellow transition' to={'/alkoto/' + props.data.alkotoDtoList[0].id}>{props.data.alkotoDtoList[0].nevDto.megjelenitendoNev}</Link>
                        <div>{props.data.nev}</div>
                        {props.data.keletkezesHelyeDto && <div className='italic'>{props.data.keletkezesHelyeDto.city}</div>}
                    </div>

                    <div className='flex items-start py-2'>
                        <div className='flex gap-2 text-4xl'>
                            {props.data.keletkezesHelyeDto && <button onClick={() => setMode('MAP')}><PiMapPinFill className='transition text-mma-blue hover:text-mma-yellow' /></button>}
                            {/* <button><PiShareNetworkBold className='transition text-mma-blue hover:text-mma-yellow' /></button> */}
                        </div>
                    </div>

                    {props.data.tovabbiKepekDtoList && <ThumbList className='hidden md:flex' data={props.data.tovabbiKepekDtoList} onItemClick={(index) => { if (mode !== 'IMAGE') setMode('IMAGE'); setActiveIndex(index + 1); }} />}

                </div>

                <div className='col-span-4 order-1 sm:order-2'>
                    {mode == 'IMAGE' && <ImageSlider dots arrows contain className='aspect-[4/3] lg:aspect-video bg-image-load' data={getImageArray(props.data.fokep, props.data.tovabbiKepekDtoList)} activeIndex={activeIndex} />}
                    {(mode == 'MAP' && props.data.keletkezesHelyeDto) && <Map className='aspect-square sm:aspect-video' data={createMapData()} />}
                </div>

            </div>

            {props.data.tovabbiKepekDtoList && <ThumbList className='md:hidden' data={props.data.tovabbiKepekDtoList} type='ROW' onItemClick={(index) => { if (mode !== 'IMAGE') setMode('IMAGE'); setActiveIndex(index + 1); }} />}

            {/* <div className='col-span-full bg-blue-400 h-16 md:hidden'></div> */}

        </div>
    );
}

// function ThumbList(props) {
//     const [width, height] = useWindowSize();
//     const [columns, setColumns] = useState(5);

//     const getRowList = (data, columns) => {
//         let fitlered = [];
//         let rows = [];
//         let temp = [];

//         for (let i = 0; i < data.length; i++) fitlered.push(data[i]);
//         // fitlered.sort((a, b) => (a.keletkezesIdopontjaInt > b.keletkezesIdopontjaInt) ? 1 : ((b.keletkezesIdopontjaInt > a.keletkezesIdopontjaInt) ? -1 : 0));
//         // fitlered.reverse();

//         for (let i = 0; i < fitlered.length; i++) {
//             temp.push(fitlered[i]);
//             if (temp.length === columns) {
//                 rows.push(temp);
//                 temp = [];
//             }
//         }
//         if (temp.length > 0) rows.push(temp);
//         // console.log(rows);
//         return rows;
//     };

//     useEffect(() => {
//         let c = 2;
//         if (width > 640) c = 5;
//         if (width > 768) c = 5;
//         if (width > 1024) c = 5;
//         if (width > 1280) c = 5;
//         if (width > 1536) c = 5;
//         setColumns(c);
//     }, [width]);

//     return (
//         <div className='w-full grid grid-rows-3 auto-cols-fr grid-flow-col gap-2'>

//             {props.data.map((item, index) => { return <button onClick={() => props.onItemClick(index)} className='w-32 h-32 aspect-square bg-neutral-200 bg-cover bg-center' key={item.id} data={item} style={{ backgroundImage: 'url(' + (global.backingsEndpoint + 'imageRepository/getImage?key=' + item.kep) + ')' }}></button> })}

//         </div>
//     );
// }

function ThumbList(props) {
    const [width, height] = useWindowSize();
    const [columns, setColumns] = useState(5);

    const getRowList = (data, columns) => {
        let fitlered = [];
        let rows = [];
        let temp = [];

        for (let i = 0; i < data.length; i++) fitlered.push(data[i]);
        // fitlered.sort((a, b) => (a.keletkezesIdopontjaInt > b.keletkezesIdopontjaInt) ? 1 : ((b.keletkezesIdopontjaInt > a.keletkezesIdopontjaInt) ? -1 : 0));
        // fitlered.reverse();

        for (let i = 0; i < fitlered.length; i++) {
            temp.push(fitlered[i]);
            if (temp.length === columns) {
                rows.push(temp);
                temp = [];
            }
        }
        if (temp.length > 0) rows.push(temp);
        // console.log(rows);
        return rows;
    };

    useEffect(() => {
        if (props.data) setColumns(props.data.length <= 3 ? props.data.length : Math.floor(props.data.length / 2));
    }, [props.data]);

    if (props.type && props.type === 'ROW')
        return (<div className={'flex gap-2 px-site ' + (props.className || '')}>

            {props.data.map((item, index) => { return <Thumb className='flex-1' image={item.kep} onClick={() => props.onItemClick(index)} /> })}

        </div>);

    return (
        <div className={'w-full h-full flex flex-col justify-end gap-2 bg-green-4000 ' + (props.className || '')}>

            {getRowList(props.data, columns).map((row) => {
                return <div className={'w-full flex-1 flex flex-row gap-2 bg-yellow-4000 ' + ((props.data.length <= columns && props.data.length !== 1) ? 'flex-grow-0' : '')}>

                    {row.map((item, index) => { return <Thumb onClick={() => props.onItemClick(index)} className={(props.data.length <= columns && props.data.length !== 1) ? 'flex-1' : ''} image={item.kep} /> })}

                </div>
            })}

        </div>
    );
}

function Thumb(props) {
    return (
        <button onClick={props.onClick} className={'relative aspect-square bg-neutral-200 group overflow-hidden ' + (props.className || '')} /*style={{ backgroundImage: 'url(' + (global.backingsEndpoint + 'imageRepository/getImage?key=' + props.image) + ')' }}*/>
            <div className='w-full h-full bg-cover bg-center group-hover:grayscale group-hover:scale-105 transition duration-1000 ' style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.image + ')' }} />
            <div className='absolute top-0 w-full h-full bg-mma-cyan group-hover:opacity-100 opacity-0 mix-blend-multiply transition' />
        </button>
    );
}

function RelatedCreationList(props) {
    const [list, setList] = useState([]);
    const [title, setTitle] = useState('További alkotások');

    useEffect(() => {
        const url = global.backingsEndpoint + 'websitePropertiesControl/findByComponentName'
        const body = { 'componentName': 'tovabbi_munkai' }

        axios.post(url, body).then(function (response) {
            if (response.data) setTitle(response.data[0].stringValue);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (props.personId) {
            const url = global.backingsEndpoint + 'contentControl/getList'
            const body = { 'firstResult': 0, 'maxResults': 1000, 'personIdList': [props.personId], 'contentType': ['architecture'], 'queryString': '', 'imageNeeded': true, 'conceptionIdList': props.activeTagList }

            axios.post(url, body).then(function (response) {
                if (response.data) setList(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [props.personId, props.activeTagList]);

    return (
        <div className='col-span-full'>
            <div className='w-full text-mma-blue text-md sm:text-xl uppercase font-bold px-site'>{title}</div>
            <Timeline data={list} child={<GenericCell />} />
        </div>
    );
}

function TagList(props) {
    return (
        <div className='w-full flex items-center justify-end'>
            <div className='max-w-full overflow-hidden'>
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} scrollContainerClassName='flex gap-1'>
                    {props.data.map((item) => { return <Tag onClick={() => { props.onTagClick(item.id, item.nev) }} label={item.nev} /*active={activeTagList.indexOf(item.id) > -1}*/ /> })}
                </ScrollMenu>
            </div>
        </div>
    );
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (<Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()} innerClassName='left-0 bg-gradient-to-r justify-start' icon={<HiOutlineChevronLeft />} />);
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (<Arrow disabled={isLastItemVisible} onClick={() => scrollNext()} innerClassName='right-0 bg-gradient-to-l justify-end' icon={<HiOutlineChevronRight />} />);
}

function Arrow(props) {
    return (
        <button className={'relative h-full bg-white transition-all ' + (props.disabled ? 'w-0' : 'w-0')} onClick={props.onClick}>

            <div className={'absolute h-full aspect-[2/1] top-0 z-10 from-white via-white to-transparent flex items-center transition-all ' + (props.disabled ? 'opacity-0 pointer-events-none ' : 'opacity-100 ') + props.innerClassName}>
                {props.icon}
            </div>

        </button>
    );
}