import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fakerHU as faker } from '@faker-js/faker';

import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';

const duration = 3;

export default function ImageSlider(props) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (props.data != null) setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        if (props.auto) {
            let timer = setTimeout(() => { setActiveIndex(getNext()); }, duration * 1000);
            return () => clearTimeout(timer);
        }
        if (props.onActiveIndexChanged)
            props.onActiveIndexChanged(activeIndex);
    }, [activeIndex])

    useEffect(() => {
        if (props.activeIndex) setActiveIndex(props.activeIndex);
    }, [props.activeIndex])

    let getNext = () => { return activeIndex + 1 >= props.data.length ? 0 : activeIndex + 1; }
    let getPrev = () => { return activeIndex - 1 < 0 ? props.data.length - 1 : activeIndex - 1; }

    // const imageList = [faker.image.urlLoremFlickr({ category: 'old,building' })/*, faker.image.urlLoremFlickr({ category: 'old,building' }), faker.image.urlLoremFlickr({ category: 'old,building' })*/];

    const buttonClassName = (props.thumbnail ? '' : '') + ' flex-1 flex items-center p-4 group';
    const buttonIconClassName = (props.thumbnail ? 'text-3xl p-1.5' : 'text-5xl p-2') + ' bg-black/20 group-hover:bg-black/40 rounded-full text-white/80 backdrop-blur-sm shadow-lg transition';

    return (
        <div className={'relative w-full h-full overflow-hidden ' + (props.className || '')}>

            {props.data.map((item, index) => { return <Slide key={index} className={props.slideClassName} data={item} active={activeIndex === index} contain={props.contain} /> })}

            {(props.data.length > 1 && props.arrows) && <div className='absolute w-full h-full flex flex-row text-xl text-white/50'>

                <button className={buttonClassName + ' justify-start'} onClick={() => setActiveIndex(getPrev())}>
                    <PiCaretLeftBold className={buttonIconClassName} />
                </button>

                <button className={buttonClassName + ' justify-end'} onClick={() => setActiveIndex(getNext())}>
                    <PiCaretRightBold className={buttonIconClassName} />
                </button>

            </div>}

            {(props.data.length > 1 && props.dots) && <div className='w-full absolute bottom-4 flex justify-center'>
                <div className={'flex justify-center gap-2 bg-black/20 rounded-full backdrop-blur-sm ' + (props.thumbnail ? 'p-1.5' : 'p-2')}>
                    {props.data.map((item, index) => { return <Dot key={index} color={props.color} onClick={() => setActiveIndex(index)} active={activeIndex === index} /> })}
                </div>
            </div>}

        </div>
    );
}

function Slide(props) {
    return (
        <motion.div
            className={'absolute top-0 left-0 w-full h-full bg-neutral-200 ' + + (props.active ? 'pointer-events-auto' : 'pointer-events-none') + ' ' + (props.className || '')}
            transition={{ duration: 1 }}
            initial={props.active ? 'visible' : 'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1/*, scale: 1*/ }, 'hidden': { opacity: 0/*, scale: 1.015*/ } }}>

            <div className={'absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover bg-center grayscale opacity-20 blur-sm' + (props.contain ? ' ' : '')} style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.key + ')' }}></div>

            <div className={'absolute top-0 left-0 w-full h-full bg-no-repeat bg-center ' + (props.contain ? 'bg-contain' : 'bg-cover')} style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.key + ')' }}></div>

        </motion.div>
    );
}

function Dot(props) {
    return (
        <button className={'w-2 h-2 rounded-full transition ' + (props.color ? (props.active ? props.color.highlightBackgroundClassName : props.color.backgroundClassName) : (props.active ? 'bg-white hover:bg-white/80' : 'bg-white/50 hover:bg-white/75'))} onClick={props.onClick} />
    );
}