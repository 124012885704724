import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { motion } from 'framer-motion';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { IoClose } from 'react-icons/io5';

import TextFilter from 'components/TextFilter';

import { useConfig } from 'providers/ConfigProvider';
import { getSearchUrl } from 'utils/GenericUtils';
import OutsideAlerter from 'utils/OutsideAlerter';

export default function Header(props) {
    const [sticky, setSticky] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();

    const search = () => { if (searchTerm !== '') navigate(getSearchUrl(searchTerm)); }

    return (
        <div className={'w-full items-center z-50 ' + (props.className || '')}>

            <LogoBar
                sticky={sticky}
                menuOpen={menuOpen}
                onMenuOpenChanged={(state) => setMenuOpen(state)}
                searchTerm={searchTerm}
                onSearchTermChanged={(value) => setSearchTerm(value)}
                searchOpen={searchOpen}
                onSearch={() => search()}
                onSearchOpenChanged={(state) => setSearchOpen(state)} />

            <MenuBar
                sticky={sticky}
                onSticky={(state) => setSticky(state)}
                menuOpen={menuOpen}
                onMenuOpenChanged={(state) => setMenuOpen(state)}
                searchTerm={searchTerm}
                onSearchTermChanged={(value) => setSearchTerm(value)}
                searchOpen={searchOpen}
                onSearch={() => search()}
                onSearchOpenChanged={(state) => setSearchOpen(state)} />

        </div>
    );
}

function LogoBar(props) {
    return (
        <div className='w-full flex flex-row justify-between px-site pt-5 sm:pt-8 sm:pb-6'>

            <Link to='/'>
                <img className='hidden sm:block w-[245px]' alt='logo' src='/assets/images/logo/logo-large.svg' />
                <img className='w-[210px] sm:hidden' alt='logo' src='/assets/images/logo/logo-large-notext.svg' />
            </Link>

            <div className='flex flex-col justify-between items-end'>

                <motion.div
                    className='hidden sm:flex'
                    initial='hidden'
                    animate={props.sticky ? 'hidden' : 'visible'}
                    variants={{ 'visible': { scale: 1, opacity: 1 }, 'hidden': { opacity: 0, scale: 0.5 } }}>

                    <ButtonBlock searchTerm={props.searchTerm} onSearchTermChanged={props.onSearchTermChanged} searchOpen={props.searchOpen} onSearch={props.onSearch} onSearchOpenChanged={props.onSearchOpenChanged} />

                </motion.div>

                <a href='https://mma.hu/' target='_blank' rel='noreferrer' className='w-[60px] -mr-[8px] opacity-50 hover:opacity-75 transition'><img alt='logo' src='/assets/images/logo/mma.png' /></a>

            </div>

        </div>
    );
}

function MenuBar(props) {
    const { setAllFilter } = useConfig();

    return (
        <Sticky activeClass='shadow-xl' innerClass='relative w-full bg-white px-site z-10' onStateChange={(status) => props.onSticky(status.status === Sticky.STATUS_FIXED)}>

            <div className='w-full flex items-center justify-between py-3'>

                <div className='relative flex items-center gap-3'>

                    <motion.img className='w-5 sm:w-6 h-5 sm:h-6 absolute'
                        alt='logo'
                        src='/assets/images/logo/logo-small.svg'
                        initial='hidden'
                        animate={props.sticky ? 'visible' : 'hidden'}
                        variants={{ 'visible': { scale: 1, opacity: 1 }, 'hidden': { opacity: 0, scale: 0.5 } }} />

                    <motion.div
                        className='flex gap-4 sm:gap-10'
                        initial='hidden'
                        animate={props.sticky ? 'visible' : 'hidden'}
                        variants={{ 'visible': { x: '15%' }, 'hidden': { x: 0 } }}>

                        <MenuItem label='Alkotások' to='alkotasok' onClick={() => setAllFilter()} />
                        <MenuItem label='Alkotók' to='alkotok' onClick={() => setAllFilter()} />
                        {/* <MenuItem label='Aktuális' to='aktualitasok' /> */}

                    </motion.div>

                </div>

                <motion.div
                    className='hidden sm:flex'
                    initial='hidden'
                    animate={props.sticky ? 'visible' : 'hidden'}
                    variants={{ 'visible': { scale: 1, opacity: 1 }, 'hidden': { opacity: 0, scale: 0.5 } }}>

                    <ButtonBlock searchTerm={props.searchTerm} onSearchTermChanged={props.onSearchTermChanged} searchOpen={props.searchOpen} onSearch={props.onSearch} onSearchOpenChanged={props.onSearchOpenChanged} />

                </motion.div>

                <DropdownMenuButton className='flex sm:hidden' open={props.menuOpen} onMenuOpenChanged={props.onMenuOpenChanged} />

            </div>

            <DropdownMenu open={props.menuOpen} onMenuOpenChanged={props.onMenuOpenChanged} />

        </Sticky>
    );
}

function ButtonBlock(props) {
    return (
        <div className='flex flex-row items-center gap-3'>
            {/* <SearchBar term={props.searchTerm} onTermChanged={props.onSearchTermChanged} open={props.searchOpen} onSearch={props.onSearch} onOpenChanged={props.onSearchOpenChanged} /> */}
            <LangSelector />
        </div>
    );
}

function LangSelector(props) {
    return (
        <div>
            <div className='text-sm leading-loose'>HU / EN</div>
        </div>
    );
}

function SearchBar(props) {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter')
            props.onSearch();
    }

    return (
        <div className='relative flex h-8'>

            <motion.div
                style={{ originX: 1, originY: 0.5 }}
                className='absolute top-0 right-0 w-48 md:w-64 h-full'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: 1, scaleX: 1 }, 'hidden': { opacity: 0, scaleX: 0 } }}>

                <input className='w-full h-full bg-neutral-200 border-2 outline-none px-2 py-1 text-[13px] font-slab' placeholder='Keresés ...' value={props.term} onChange={(e) => props.onTermChanged(e.target.value)} onKeyDown={handleKeyDown} />

            </motion.div>

            <button className='relative h-full aspect-square flex items-center justify-center z-10' onClick={() => props.onOpenChanged(!props.open)}>

                <motion.div className='absolute' initial={props.open ? 'hidden' : 'visible'} animate={props.open ? 'hidden' : 'visible'} variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 0 } }}>
                    <PiMagnifyingGlass className={'text-mma-blue text-[32px]'} />
                </motion.div>

                <motion.div className='absolute' initial={props.open ? 'visible' : 'hidden'} animate={props.open ? 'visible' : 'hidden'} variants={{ 'visible': { opacity: 1, scale: 1 }, 'hidden': { opacity: 0, scale: 0 } }}>
                    <IoClose className={'text-mma-blue text-[20px]'} />
                </motion.div>

            </button>

        </div>
    );
}

function MenuItem(props) {
    return (
        <NavLink to={props.to} onClick={props.onClick}>
            {({ isActive }) => (
                <>
                    <div className='font-circular font-bold tracking-wide text-sm sm:text-lg lg:text-xl'>{props.label}</div>

                    <motion.div
                        className='h-0.5 bg-black'
                        initial={isActive}
                        animate={isActive ? 'visible' : 'hidden'}
                        variants={{ 'visible': { width: '100%', opacity: 1 }, 'hidden': { width: 0, opacity: 0 } }} />
                </>
            )}
        </NavLink>
    );
}

function DropdownMenuButton(props) {
    return (
        <button className={'w-1 flex flex-col gap-[3px] ' + (props.className || '')} onClick={() => { if (!props.open) { props.onMenuOpenChanged(!props.open); /*console.log('Button click.');*/ } }}>
            <div className='w-full aspect-square rounded-full bg-neutral-400' />
            <div className='w-full aspect-square rounded-full bg-neutral-400' />
            <div className='w-full aspect-square rounded-full bg-neutral-400' />
        </button>
    );
}

function DropdownMenu(props) {
    const [terms, setTerms] = useState('');
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const search = () => { if (terms !== '') navigate(getSearchUrl(terms)); }

    useEffect(() => { setOpen(props.open) }, [props.open]);

    return (
        // <div>
        <OutsideAlerter active={open} onOutsideClick={() => { if (open) props.onMenuOpenChanged(false); /*console.log('Outside click.');*/ }} className='absolute sm:hidden bottom-0 left-0 w-full h-0'>

            <motion.div
                className={'w-full flex flex-col bg-white shadow-2xl px-site pb-2'}
                initial='hidden'
                style={{ originX: 0.5, originY: 0, transformPerspective: 1500, backfaceVisibility: 'hidden' }}
                animate={props.open ? 'open' : 'hidden'}
                variants={{ 'open': { opacity: 1, rotateX: 0, display: 'flex' }, 'hidden': { opacity: 0, rotateX: -70, transitionEnd: { display: 'none' } } }}>

                {/* <TextFilter placeholder='Keresett kifejezés ...' value={terms} onChange={(e) => setTerms(e.target.value)} onButtonClick={() => search()} /> */}

                <div className='flex flex-col mt-2'>
                    <DropdownMenuItem label='HU/EN' onClick={() => props.onMenuOpenChanged(false)} />
                    <DropdownMenuItem label='IMPRESSZUM' to='/impresszum' onClick={() => props.onMenuOpenChanged(false)} />
                    {/* <DropdownMenuItem label='LEÍRÁS' /> */}
                </div>

            </motion.div>

        </OutsideAlerter>
        // </div>
    );
}

function DropdownMenuItem(props) {
    return (
        <Link className='font-bold text-sm uppercase py-1.5' to={props.to} onClick={props.onClick}>{props.label}</Link>
    );
}