import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Impressum(props) {
    return (
        <div className={'w-full sm:p-2 flex flex-col items-center justify-center ' + (props.className || '')}>
            <div className='w-full bg-mma-blue flex flex-col gap-8 items-center justify-center py-12 flex-1 h-full'>

                <div className='text-mma-yellow text-2xl font-bold uppercase font-slab'>Impresszum</div>

                <div className='text-white leading-relaxed flex items-center flex-col xl:flex-row gap-2 xl:gap-0 xl:divide-x divide-white/10'>
                    <ImpressumItem logo='/assets/images/logo/mma-white-transparent.png' link='https://mma.hu/' label='Kiadó' content='Magyar Művészeti Akadémia Titkársága' />
                    <ImpressumItem label='Felelős kiadó' content='Dr. Richly Gábor főtitkár' />
                    <ImpressumItem label='Felelős szerkesztő' content='Csete Örs főosztályvezető' />
                </div>

                <div className='flex flex-col gap-6 text-white items-center text-sm'>
                    <ImpressumContactItem label='Kapcsolat' title='Magyar Művészeti Akadémia Titkársága, Tagozati és Dokumentációs Főosztály, Digitalizációs Központ' address='1051 Budapest, Vörösmarty tér 1., 4. emelet' email='digikozpont@mma.hu ' />
                    <ImpressumContactItem href='https://creo.hu/' logo='/assets/images/logo/creo.png' label='Fejlesztés, tárhely szolgáltatás' title='creo.hu Kft.' address='1088 Budapest, Rákóczi út 19. 3/1a.' email='info@creo.hu' />
                </div>

                {/* <div className='text-white leading-relaxed text-sm text-center sm:-mx-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vestibulum vel erat et dictum. Suspendisse feugiat vitae tellus eu auctor. Maecenas volutpat vulputate enim, a auctor magna egestas vitae. In egestas mattis leo. Cras iaculis nisl ac facilisis rhoncus. Aliquam aliquet tellus vitae erat dictum imperdiet. Sed ut volutpat est. Etiam et elit vitae lectus molestie ornare sit amet quis nulla. In id massa non ligula dictum euismod id blandit turpis. Curabitur molestie sodales justo, in suscipit mauris vehicula nec. Pellentesque blandit purus vel tristique aliquet. Donec at lacus sem.</div> */}

            </div>
        </div>
    );
}

function ImpressumItem(props) {
    return (
        <div className='flex flex-col sm:flex-row gap-1 sm:gap-2 items-center px-2 text-sm'>

            <div className='font-bold'>{props.label}:</div>

            <a href={props.link} target='_blank' rel='noreferrer' className='flex items-center gap-2'>
                {props.logo && <div className='w-[35px] opacity-50 group-hover:opacity-75 transition mb-0.5'><img alt='logo' src={props.logo} /></div>}
                <div className='font-thin'>{props.content}</div>
            </a>

        </div>
    );
}

function ImpressumContactItem(props) {
    return (
        <div className='flex flex-row items-center px-4'>

            <div className='flex flex-col gap-1 items-center'>
                <ImpressumContactItemLine title={props.label} content={props.title} logo={props.logo} href={props.href} />
                <ImpressumContactItemLine title='Cím' content={props.address} />
                <ImpressumContactItemLine title='E-mail' content={props.email} href={'mailto:' + props.email} />
            </div>

        </div>
    );
}

function ImpressumContactItemLine(props) {
    return (
        <div className='flex flex-col lg:flex-row items-center gap-1 lg:gap-2 px-2'>

            <div className='font-bold '>{props.title}:</div>

            <a className={'flex items-center gap-2 opacity-90 transition ' + (props.href ? 'hover:opacity-100' : '')} href={props.href}>
                {props.logo && <div className='w-[60px] opacity-50 mb-0.5 px-0.5 mt-0.5'><img alt='logo' src={props.logo} /></div>}
                <div className='font-light text-center'>{props.content}</div>
            </a>

        </div>
    );
}

export function ImpressumFooter(props) {
    let location = useLocation();

    if (location && location.pathname !== '/impresszum') return <Impressum />
    return null;
}