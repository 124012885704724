import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ImageSlider from 'components/ImageSlider';

import { getColorConfig, getColorByIndex } from 'utils/StyleUtils';

export default function ActualListPage(props) {
    const [list, setList] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        const url = global.backingsEndpoint + 'eventControl/getList'
        const body = { 'firstResult': 0, 'maxResults': 100 }

        axios.post(url, body).then(function (response) {
            if (response.data) setList(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    return (
        <div className={'w-full flex flex-col px-site-list pb-page pt-page ' + (props.className || '')}>

            <div className='w-full flex flex-col'>

                {list.map((item, index) => { return <ActualItem key={index} index={index} data={item} open={item.id == id} /> })}

            </div>

        </div>
    );
}

function ActualItem(props) {
    const color = getColorByIndex(props.index);
    const contrastingClassName = getColorConfig(color).contrastingTextClassName + ' sm:text-black';
    const highlightTextClassName = getColorConfig(color).highlightTextClassName + ' sm:text-mma-blue';

    return (
        <Link to={'/aktualitasok/' + props.data.id} className='w-full h-56 flex'>

            <div className='relative w-full mr-0 sm:mr-2 pr-0 sm:pr-2 flex py-0 sm:py-2 sm:hover:bg-mma-yellow transition pl-4 -ml-4'>

                <div className={'absolute block sm:hidden w-full h-full -z-10 ' + getColorConfig(color).backgroundClassName} />

                {props.data.image && <div className={'w-[40%] bg-image-load ' + (props.index % 2 ? 'order-1' : 'order-2')}>

                    <ImageSlider className='w-full h-full' slideshow image={props.data.image} />

                </div>}

                <div className={'flex-1 flex items-center justify-center p-4 sm:pl-0 ' + contrastingClassName + (props.index % 2 ? ' order-2 sm:order-none' : ' order-1')}>

                    <div className='w-full flex flex-col gap-2'>
                        <div className={'font-slab font-bold text-2xl sm:text-3xl lg:text-4xl tracking-wide ' + highlightTextClassName}>{props.data.kezdoIdopontInt}</div>
                        <div className='font-slab font-bold leading-tight sm:pt-4 sm:text-lg lg:text-2xl'>{props.data.nev}</div>
                        <div className='line-clamp-2 text-xs sm:text-sm'>{props.data.short}</div>
                    </div>

                </div>

            </div>

            <div className='w-4 bg-mma-yellow order-3 flex items-center justify-center overflow-hidden'>
                <div className='font-slab font-bold text-mma-blue -rotate-90'>{props.data.kezdoIdopontInt}</div>
            </div>

        </Link>
    );
}