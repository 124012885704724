import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function useOutsideAlerter(ref, active, cb) {
    useEffect(() => {
        document.removeEventListener('click', handleClickOutside);

        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && active && cb)
                cb();
        }

        document.addEventListener('click', handleClickOutside);

        return () => { document.removeEventListener('click', handleClickOutside); };
    }, [ref, active, cb]);
}

function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.active, props.onOutsideClick);

    return <div className={props.className} ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideAlerter;
