// import { Link, NavLink, useNavigate } from 'react-router-dom';

// const navigate = useNavigate();

export const getByKeyValue = (list, key, value) => {
    for (let i = 0; i < list.length; i++)
        if (list[i][key] === value) return list[i];
    return null;
};

// export const search = (searchTerm) => { if (searchTerm !== '') navigate('/kereses?kifejezes=' + searchTerm); }

export const getSearchUrl = (searchTerm, type, tag, title) => {
    let s = '';
    let t = '';
    let ta = '';
    let ti = '';

    if (searchTerm && searchTerm !== '') s = 'kifejezes=' + searchTerm;
    if (type && type !== '') t = 'tipus=' + type;
    if (tag && tag !== '') ta = 'targyszo=' + tag;
    if (title && title !== '') ti = 'cim=' + title;

    return '/kereses' + (s ? ('?' + s) : '') + (t ? ((s ? '&' : '?') + t) : '') + (ta ? (((s || t) ? '&' : '?') + ta) : '') + (ti ? (((s || t || ta) ? '&' : '?') + ti) : '');
}

export const __tempConvertLife = (data) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
        const _data = data[i];
        _data.isLife = true;
        _data.keletkezesIdopontjaInt = data[i].kezdoIdopontInt;
        arr.push(_data);
    }
    return arr;
    // return data;
};