import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PiArrowsClockwiseBold, PiCheckBold } from 'react-icons/pi';
import { GenericCell } from 'components/Timeline';

import { useConfig } from 'providers/ConfigProvider';
import { __tempConvertLife } from 'utils/GenericUtils';

const rowGapClassName = 'gap-12 sm:gap-24 xl:gap-36';

export default function ParallelPathsPage(props) {
    const [creatorLeft, setCreatorLeft] = useState(null);
    const [creationsLeft, setCreationsLeft] = useState(null);
    const [lifeLeft, setLifeLeft] = useState(null);

    const [creatorRight, setCreatorRight] = useState(null);
    const [creationsRight, setCreationsRight] = useState(null);
    const [lifeRight, setLifeRight] = useState(null);

    const [nameList, setNameList] = useState([]);

    const [list, setList] = useState([]);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const url = global.backingsEndpoint + 'personControl/getNameList'
        const body = {}

        axios.post(url, body).then(function (response) {
            if (response.data) setNameList(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (searchParams.get('bal')) {
            getCreator(searchParams.get('bal'), (data) => setCreatorLeft(data));
            getCreationList(searchParams.get('bal'), (data) => setCreationsLeft(data));
            getLifeList(searchParams.get('bal'), (data) => setLifeLeft(data));
        }
        if (searchParams.get('jobb')) {
            getCreator(searchParams.get('jobb'), (data) => setCreatorRight(data));
            getCreationList(searchParams.get('jobb'), (data) => setCreationsRight(data));
            getLifeList(searchParams.get('jobb'), (data) => setLifeRight(data));
        }
    }, [searchParams]);

    const getCreator = (id, cb) => {
        const url = global.backingsEndpoint + 'personControl/getData'
        const body = { 'personId': id }

        axios.post(url, body).then(function (response) {
            if (response.data && cb) cb(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const getCreationList = (id, cb) => {
        if (id) {
            const url = global.backingsEndpoint + 'contentControl/getList'
            const body = { 'firstResult': 0, 'maxResults': 100000, 'personIdList': [parseInt(id)], 'contentType': ['architecture'], 'queryString': '', 'imageNeeded': false, 'conceptionIdList': [] }

            axios.post(url, body).then(function (response) {
                if (response.data) cb(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        }
    };

    const getLifeList = (id, cb) => {
        if (id) {
            const url = global.backingsEndpoint + 'personControl/getWalkOfLife'
            const body = { 'personId': parseInt(id) }

            axios.post(url, body).then(function (response) {
                if (response.data) cb(__tempConvertLife(response.data));
            }).catch(function (error) {
                console.log(error);
            });
        }
    };

    const setCreatorIdLeft = (id) => { setSearchParams({ bal: id, jobb: searchParams.get('jobb') }); };
    const setCreatorIdRight = (id) => { setSearchParams({ bal: searchParams.get('bal'), jobb: id }); };

    useEffect(() => {
        const years = getYears([creationsLeft, creationsRight, lifeLeft, lifeRight]);
        years.sort(function (a, b) { return b - a; });
        let list = [];
        for (let i = 0; i < years.length; i++)
            list.push({ 'year': years[i], left: getDataForYear(years[i], [creationsLeft, lifeLeft]), right: getDataForYear(years[i], [creationsRight, lifeRight]) });
        setList(list)
    }, [creationsLeft, creationsRight, lifeLeft, lifeRight]);

    const getYears = (arr) => {
        let years = [];
        for (let j = 0; j < arr.length; j++) {
            if (arr[j])
                for (let i = 0; i < arr[j].length; i++)
                    if (years.indexOf(arr[j][i]['keletkezesIdopontjaInt']) === -1) years.push(arr[j][i]['keletkezesIdopontjaInt']);
        }
        // if (data_1) for (let i = 0; i < data_1.length; i++) if (years.indexOf(data_1[i]['keletkezesIdopontjaInt']) === -1) years.push(data_1[i]['keletkezesIdopontjaInt']);
        // if (data_2) for (let i = 0; i < data_2.length; i++) if (years.indexOf(data_2[i]['keletkezesIdopontjaInt']) === -1) years.push(data_2[i]['keletkezesIdopontjaInt']);
        return years;
    };

    // const getYears = (data_1, data_2) => {
    //     let years = [];
    //     if (data_1) for (let i = 0; i < data_1.length; i++) if (years.indexOf(data_1[i]['keletkezesIdopontjaInt']) === -1) years.push(data_1[i]['keletkezesIdopontjaInt']);
    //     if (data_2) for (let i = 0; i < data_2.length; i++) if (years.indexOf(data_2[i]['keletkezesIdopontjaInt']) === -1) years.push(data_2[i]['keletkezesIdopontjaInt']);
    //     return years;
    // };

    const getDataForYear = (year, arr) => {
        let list = [];
        if (arr)
            for (let j = 0; j < arr.length; j++) {
                if (arr[j])
                    for (let i = 0; i < arr[j].length; i++)
                        if (arr[j][i]['keletkezesIdopontjaInt'] == year) list.push(arr[j][i]);
            }
        return list;
    };

    // const getDataForYear = (year, data) => {
    //     let list = [];
    //     if (data) for (let i = 0; i < data.length; i++) if (data[i]['keletkezesIdopontjaInt'] == year) list.push(data[i]);
    //     return list;
    // };

    return (
        <div className={'w-full flex flex-col items-center px-site pb-page pt-page  ' + (props.className || '')}>

            <div className='w-full sm:w-[75%] max-w-[820px] flex flex-col'>

                <div className={'flex ' + rowGapClassName}>
                    <ParalellHeader data={creatorLeft} nameList={nameList} onChange={(id) => setCreatorIdLeft(id)} />
                    <ParalellHeader data={creatorRight} nameList={nameList} onChange={(id) => setCreatorIdRight(id)} />
                </div>

                <div className='w-full'>
                    {list.map((item, index) => { return <ParalellRow key={index} index={index} data={item} /> })}
                </div>

            </div>

        </div>
    );
}

function ParalellRow(props) {
    const topPaddingClassName = 'pt-6'

    return (
        <div className={'relative flex ' + rowGapClassName}>

            <div className={'flex-1 flex flex-col gap-4 pb-4 z-10 ' + (props.index ? '' : topPaddingClassName)}>
                {props.data.left.map((item, index) => { return <GenericCell key={index} data={item} /> })}
            </div>

            <div className='absolute w-full h-full flex justify-center'>
                <div className={'relative w-4 h-full bg-mma-yellow flex items-start justify-center overflow-hiddenn ' + (props.index ? '' : topPaddingClassName)}>
                    <div className='font-slab font-bold text-mma-blue py-2 -rotate-90'>{props.data.year}</div>
                    {!props.index && <div className='absolute top-0 w-full h-0'><div className='relative w-full h-full'><div className='absolute bottom-0 w-full h-36 sm:h-52 lg:h-64 bg-gradient-to-b from-transparent to-mma-yellow'></div></div></div>}
                </div>
            </div>

            <div className={'flex-1 flex flex-col gap-4 pb-4 z-10 ' + (props.index ? '' : topPaddingClassName)}>
                {props.data.right.map((item, index) => { return <GenericCell key={index} data={item} /> })}
            </div>

        </div>
    );
}

function ParalellHeader(props) {
    const [edit, setEdit] = useState(false);
    const [selected, setSelected] = useState(null);
    const [category, setCategory] = useState('architecture');

    const { categoryList } = useConfig();

    const selectClassName = 'w-full px-2 text-center outline-none py-0';

    const handleButtonClick = () => {
        // if (edit) props.onChange(selected);
        setEdit(!edit);
    };

    useEffect(() => {
        // setEdit(!edit);
        if (selected) {
            props.onChange(selected)
            setEdit(!edit);
        }
    }, [selected]);

    return (
        <div className='flex-1 flex flex-col items-center gap-4'>

            <Link to={props.data ? ('/alkoto/' + props.data.id) : null} className={'relative w-full aspect-square bg-image-load rounded-full overflow-hidden ' + (props.data ? 'group' : 'pointer-events-none')}>
                {props.data && <>
                    <div className='w-full h-full bg-top bg-cover grayscale group-hover:scale-105 transition duration-1000' style={{ backgroundImage: 'url(' + global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.profilkep + ')' }} />
                    <div className='absolute top-0 w-full h-full rounded-full bg-mma-cyan group-hover:opacity-100 opacity-0 mix-blend-multiply transition' />
                </>}
            </Link>

            <div className='w-full relative flex justify-center'>

                <div className='absolute left-0 h-full flex items-center justify-center'>
                    <div className={'w-8 lg:w-12 aspect-square flex items-center justify-center rounded-full transition-all ' + (edit ? 'text-mma-yellow bg-mma-blue' : 'text-mma-blue bg-neutral-200')} onClick={() => handleButtonClick()}>
                        {/* {edit ? <PiCheckBold className='text-sm ' /> : <PiArrowsClockwiseBold />} */}
                        <PiArrowsClockwiseBold />
                    </div>
                </div>

                <div className={'w-[70%] relative flex flex-col text-center mx-10 lg:mx-16 ring-2 ' + (edit ? 'ring-mma-yellow rounded-[1px] ' : 'ring-transparent')} >

                    {/* <div className='h-full flex items-center justify-center'>
                    <div className={'aspect-square rounded-full p-2 transition-all ' + (edit ? 'text-mma-yellow bg-mma-blue' : 'text-mma-blue bg-neutral-200')} onClick={() => handleButtonClick()}>
                        {edit ? <PiCheckBold className='text-sm ' /> : <PiArrowsClockwiseBold />}
                    </div>
                </div> */}

                    <div className=''>
                        {!edit && <div className='font-slab font-bold text-sm lg:text-lg my-[2px]'>{props.data ? ((props.data.elhalalozasIdeje ? '✝ ' : '') + props.data.nevDto.megjelenitendoNev) : 'Válasszon alkotót!'}</div>}
                        {edit && <select value={selected} onChange={(e) => setSelected(e.target.value)} className={selectClassName + ' text-sm font-bold font-slab'}>
                            <option value="" disabled selected>Válasszon alkotót!</option>
                            {props.nameList.map((item) => { return (!category || item.tipus === category) && <option value={item.id}>{item.vezeteknev} {item.keresztnev1}{item.keresztnev2 ? (' ' + item.keresztnev2) : ''}</option> })}
                        </select>}
                    </div>

                    {/* <div className=''>
                        {!edit && <div className='text-sm '>{props.data ? props.data.szakmaDto.name : 'N/A'}</div>}
                        {edit && <select disabled className={selectClassName + ' text-xs'} value={category} onChange={(e) => setCategory(e.target.value)}>
                            {categoryList.map((item) => { return <option value={item.key}>{item.label}</option> })}
                        </select>}
                    </div> */}

                </div>

            </div>

        </div>
    );
}