import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getColorConfig } from 'utils/StyleUtils';
import { getSearchUrl } from 'utils/GenericUtils';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() { setSize([window.innerWidth, window.innerHeight]); }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Timeline(props) {
    const [width, height] = useWindowSize();
    const [columns, setColumns] = useState(5);

    const getRowList = (data, columns) => {
        let fitlered = [];
        let rows = [];
        let temp = [];

        for (let i = 0; i < data.length; i++) if (data[i].keletkezesIdopontjaInt) fitlered.push(data[i]);
        fitlered.sort((a, b) => (a.keletkezesIdopontjaInt > b.keletkezesIdopontjaInt) ? 1 : ((b.keletkezesIdopontjaInt > a.keletkezesIdopontjaInt) ? -1 : 0));
        fitlered.reverse();

        for (let i = 0; i < fitlered.length; i++) {
            temp.push(fitlered[i]);
            if (temp.length === columns) {
                rows.push(temp);
                temp = [];
            }
        }
        if (temp.length > 0) rows.push(temp);
        return rows;
    };

    useEffect(() => {
        let c = 2;
        if (width > 640) c = 3;
        if (width > 768) c = 3;
        if (width > 1024) c = 3;
        if (width > 1280) c = 4;
        if (width > 1536) c = 5;
        setColumns(c);
    }, [width]);

    return (
        <div className={'w-full ' + (props.className || '')}>

            <div className='w-full flex'>

                <div className='w-full flex flex-col' >

                    {getRowList(props.data, columns).map((item, index) => { return <Row className={props.rowClassName} {...props} key={index} data={item} columns={columns} /> })}

                </div>

            </div>

        </div>
    );
}

function Row(props) {
    const rowConatainsImage = () => {
        for (let i = 0; i < props.data.length; i++) if (props.data[i].fokep) return true;
        return false;
    };

    return (
        <div className={'flex px-site-list ' + (props.rowClassName || '')}>

            <div className={'flex-1 flex gap-4 py-4 px-site-list-inner ' + (props.rowInnerClassName || '')}>

                {[...Array(props.columns)].map((item, index) => { return props.data[index] ? React.cloneElement(props.child, { data: props.data[index], rowConatainsImage: rowConatainsImage() }) : <div className='flex-1' /> })}

            </div>

            <div className={'relative w-4 flex items-center justify-center overflow-hidden lg:overflow-visible ' + (props.lineColor ? getColorConfig(props.lineColor).backgroundClassName : 'bg-mma-yellow')}>
                <div className='lg:w-0 lg:absolute right-0'>
                    {props.data[0].keletkezesIdopontjaInt !== 0 && <div className='font-slab font-bold text-mma-blue -rotate-90 lg:rotate-0 lg:pl-2'>{props.data[0].keletkezesIdopontjaInt}</div>}
                </div>

            </div>

        </div>
    );
}

export function GenericCell(props) {
    // if (!props.data.isLife) console.log(props.data.id);

    const lifeLabelMap = {
        'dij': 'Díj',
        'munkahely': 'Munkahely',
        'iskola': 'Iskola',
        'szuletes': 'Született',
        'elhalalozas': 'Elhunyt',
    };

    return (
        <Link className={'flex-1 flex flex-col gap-2 justify-start ' + (props.data.id ? '' : 'pointer-events-none')} to={props.data.id ? ('/alkotas/' + props.data.id) : null}>

            {props.data.fokep && <div className='w-full aspect-square bg-image-load bg-cover bg-center' style={{ backgroundImage: 'url(' + (global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.fokep) + ')' }}></div>}
            {!props.data.fokep && <div className={'w-full flex items-end ' + (props.rowConatainsImage ? 'aspect-square' : '')}><div className={'h-1 w-[50%] aspect-square  ' + (!props.data.isLife ? 'bg-mma-yellow' : 'bg-mma-cyan')} /></div>}

            <div className='w-full flex flex-col gap-1'>

                {props.data.isLife && <>
                    {props.data.keletkezesIdopontjaInt && <div className='font-slab font-bold text-lg'>{props.data.keletkezesIdopontjaInt + (props.data.zaroIdopontInt ? (' - ' + (props.data.zaroIdopontInt === 9999 ? '' : props.data.zaroIdopontInt)) : '')}</div>}
                    {props.data.nev && <div className='text-sm' dangerouslySetInnerHTML={{ __html: props.data.nev.replace('|', '<br/>') }} />}
                    {props.data.tipus && <div className='text-xs capitalize'>{lifeLabelMap[props.data.tipus] ? lifeLabelMap[props.data.tipus] : props.data.tipus}</div>}
                </>}

                {!props.data.isLife && <>
                    {props.data.keletkezesIdopontjaInt && <div className='font-slab font-bold text-lg'>{props.data.keletkezesIdopontjaInt}</div>}
                    {props.data.nev && <div className='text-sm '>{props.data.nev}</div>}
                    {props.data.alkotoId && <Link to={'/alkoto/' + props.data.alkotoId} className='text-sm uppercase hover:underline'>{props.data.megjelenitendoNev}</Link>}
                    {props.data.telepules && <Link to={getSearchUrl(props.data.telepules, 'alkotas', null, props.data.telepules)}><div className='text-xs hover:underline'>{props.data.telepules}</div></Link>}
                </>}

            </div>

        </Link>
    );
}

// export function GenericCell(props) {
//     return (
//         <Link className={'flex-1 flex flex-col gap-2 justify-start ' + (props.to ? '' : 'pointer-events-none')} to={props.to ? props.to : null}>

//             {props.image && <div className='w-full aspect-square bg-image-load bg-cover bg-center' style={{ backgroundImage: 'url(' + props.image + ')' }}></div>}
//             {!props.image && <div className={'w-full flex items-end ' + (props.rowConatainsImage ? 'aspect-square' : '')}><div className={'h-1 w-[50%] aspect-square  ' + (props.data.tipus ? 'bg-mma-yellow' : 'bg-mma-cyan')} /></div>}

//             <div className='w-full flex flex-col gap-1'>
//                 {props.children}
//             </div>

//         </Link>
//     );
// }

// export function GenericCreationCell(props) {
//     console.log(props.data);
//     return (
//         <GenericCell to={props.data.id ? ('/alkotas/' + props.data.id) : null} {...props} image={props.data.fokep && global.backingsEndpoint + 'imageRepository/getImage?key=' + props.data.fokep}>

//             {props.data.keletkezesIdopontjaInt && <div className='font-slab font-bold text-lg'>{props.data.keletkezesIdopontjaInt}</div>}
//             {props.data.nev && <div className='text-sm '>{props.data.nev}</div>}
//             {props.data.alkotoId && <Link to={'/alkoto/' + props.data.alkotoId} className='text-sm uppercase hover:underline'>{props.data.megjelenitendoNev}</Link>}
//             {props.data.telepules && <Link to={getSearchUrl(props.data.telepules, 'alkotas')}><div className='text-xs hover:underline'>{props.data.telepules}</div></Link>}

//         </GenericCell>
//     );
// }