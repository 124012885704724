import './global.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ConfigProvider from 'providers/ConfigProvider';

import Header from 'components/Header';
import {ImpressumFooter} from 'components/Impressum';

import HomePage from 'pages/HomePage';
import CreatorListPage from 'pages/CreatorListPage';
import CreatorPage from 'pages/CreatorPage';
import CreationListPage from 'pages/CreationListPage';
import CreationPage from 'pages/CreationPage';
import ActualListPage from 'pages/ActualListPage';
import ParallelPathsPage from 'pages/ParallelPathsPage';
import ActualPage from 'pages/ActualPage';
import SearchPage from 'pages/SearchPage';
import ImpressumPage from 'pages/ImpressumPage';

import ScrollToTop from 'utils/ScrollToTop';

function App() {
  return (
    <ConfigProvider>

      <Router>

        <ScrollToTop />

        <div className='relative w-full min-h-screen flex flex-col'>

          <Header />

          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/alkotok' element={<CreatorListPage />} />
            <Route path='/alkoto/:id' element={<CreatorPage />} />
            <Route path='/alkotasok' element={<CreationListPage />} />
            <Route path='/alkotas/:id' element={<CreationPage />} />
            <Route path='/aktualitasok/:id?' element={<ActualListPage />} />
            {/* <Route path='/aktualitas/:key' element={<ActualPage />} /> */}
            <Route path='/parhuzamos-eletutak' element={<ParallelPathsPage />} />
            <Route path='/kereses' element={<SearchPage />} />
            <Route path='/impresszum' element={<ImpressumPage />} />
          </Routes>

          <ImpressumFooter />

        </div>

      </Router>

    </ConfigProvider>
  );
}

export default App;