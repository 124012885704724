import Impressum from 'components/Impressum';

export default function ImpressumPage(props) {
    return (
        <div className={'w-full flex flex-col items-center pt-page flex-1 ' + (props.className || '')}>

            <Impressum className='flex-1' />

        </div>
    );
}